<script>
import moment from 'moment'
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'parkingGuide',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {},
  methods: {},
  render() {
    return (
      <div class="box">
        <a href="www.baidu.com">点击跳转停车导览</a>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.box{
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
}
</style>
